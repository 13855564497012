import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1, PageWrapper } from '../style/common/common'
// import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const NotFoundPage = ({pageContext, location}) => {
  // const { breadcrumb: { crumbs }, } = pageContext
  const title = "404 Not Found"

  return (
    <Layout
      location={location}
    >
      <SEO title={title} description="ページが見つかりません" />
      <PageWrapper>
        {/* <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel={title}
          /> */}
        <H1>{title}</H1>
        {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
        <Link to="/">トップページへ</Link>
      </PageWrapper>
    </Layout>
  )
}

export default NotFoundPage
